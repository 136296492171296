<template>
  <div class="hometabbar">
    <van-tabbar v-model="active">
      <van-tabbar-item to="/" icon="home-o">XiaoSi</van-tabbar-item>
      <van-tabbar-item to="/HomeActivity" icon="good-job-o">Activity</van-tabbar-item>
      <van-tabbar-item to="/HomeKnow" icon="passed">Know</van-tabbar-item>
      <van-tabbar-item to="/HomeMy" icon="user-o">My</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'HomeTabbar',
  setup() {
    const active = ref(0);
    return { active };
  },
  // 作为子组件，定义该props用于父组件给子组件传递变量，将变量值显示到子组件中, msg是变量名， String是变量类型
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
