import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomeXiaosi',
    component: () => import('@/views/home/HomeXiaosi.vue'),
    meta: {
      title: 'HomeXiaosi',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/Searchcontent',
    name: 'Searchcontent',
    component: () => import('@/views/home/Searchcontent.vue'),
    meta: {
      title: 'Searchcontent',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/IdeaEditPage',
    name: 'IdeaEditPage',
    component: () => import('@/views/home/IdeaEditPage.vue'),
    meta: {
      title: 'IdeaEditPage',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/IdeaDisplayPage',
    name: 'IdeaDisplayPage',
    component: () => import('@/views/home/IdeaDisplayPage.vue'),
    meta: {
      title: 'IdeaDisplayPage',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/ProblemEditPage',
    name: 'ProblemEditPage',
    component: () => import('@/views/home/ProblemEditPage.vue'),
    meta: {
      title: 'ProblemEditPage',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/ProblemDisplayPage',
    name: 'ProblemDisplayPage',
    component: () => import('@/views/home/ProblemDisplayPage.vue'),
    meta: {
      title: 'ProblemDisplayPage',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/HomeKnow',
    name: 'HomeKnow',
    component: () => import('@/views/know/HomeKnow.vue'),
    meta: {
      title: 'HomeKnow',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/KnowManage',
    name: 'KnowManage',
    component: () => import('@/views/know/KnowManage.vue'),
    meta: {
      title: 'KnowManage',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/knowledgedashboard',
    name: 'knowledgedashboard',
    component: () => import('@/views/know/KnowledgeDashboard.vue'),
    meta: {
      title: 'KnowledgeDashboard',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/KnowledgeTypeList',
    name: 'KnowledgeTypeList',
    component: () => import('@/views/know/KnowtypeList.vue'),
    meta: {
      title: 'KnowledgeTypeList',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/KnowtypeTree',
    name: 'KnowtypeTree',
    component: () => import('@/views/know/KnowtypeTree.vue'),
    meta: {
      title: 'KnowtypeTree',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/KnowPage',
    name: 'KnowPage',
    component: () => import('@/views/know/KnowPage.vue'),
    meta: {
      title: 'KnowPage',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/KnowEditPage',
    name: 'KnowEditPage',
    component: () => import('@/views/know/KnowEditPage.vue'),
    meta: {
      title: 'KnowEditPage',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/HomeMy',
    name: 'HomeMy',
    component: () => import('@/views/user/HomeMy.vue'),
    meta: {
      title: 'HomeMy',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/userview',
    name: 'userview',
    component: () => import('@/views/user/UserView.vue'),
    meta: {
      title: 'UserView',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/HomeActivity',
    name: 'HomeActivity',
    component: () => import('@/views/activity/HomeActivity.vue'),
    meta: {
      title: 'HomeActivity',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/ActivityManage',
    name: 'ActivityManage',
    component: () => import('@/views/activity/ActivityManage.vue'),
    meta: {
      title: 'ActivityManage',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/TaskPage',
    name: 'TaskPage',
    component: () => import('@/views/activity/TaskPage.vue'),
    meta: {
      title: 'TaskPage',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/TaskEditPage',
    name: 'TaskEditPage',
    component: () => import('@/views/activity/TaskEditPage.vue'),
    meta: {
      title: 'TaskEditPage',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/taskview',
    name: 'taskview',
    component: () => import('@/views/activity/TaskView.vue'),
    meta: {
      title: 'TaskView',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/tasktype',
    name: 'tasktype',
    component: () => import('@/views/activity/TaskType.vue'),
    meta: {
      title: 'TaskType',
      keepAlive: false // 需要缓存
    }
  },
  {
    path: '/TaskTypeList',
    name: 'TaskTypeList',
    component: () => import('@/views/activity/TaskTypeList.vue'),
    meta: {
      title: 'TaskTypeList',
      keepAlive: false // 需要缓存
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
