<template>
  <div>
    <!-- <router-view v-slot="{ Component }">
      <transition name="router-fade" mode="out-in">
          <keep-alive>
              <component :is="Component"/>
          </keep-alive>
      </transition>
    </router-view>

    <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" v-wechat-title='$route.meta.title'></router-view> -->

    <router-view v-wechat-title='$route.meta.title' />
    <HomeTabbar />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeTabbar from '@/components/HomeTabbar.vue'

export default {
  components: {
    HomeTabbar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
